import { default as account_45lookupVj0ifXYucAMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as addVPQHIVCO8oMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deleted2Dy9Y083NFMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_93I8k3BERfXNMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as indexSgja2ryWZ0Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93G30dhEX0z0Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companiesF8kniFV9mLMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_93IQ0fRdwW3HMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as indexfRNUQE2YBAMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as index28HwQIgmKVMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboardZrfqY0rQW0Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addKfTGuPFV0mMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_933NYfmS9OpmMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_93b665KpCE3kMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as index6OMOdslrlDMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_938GDkheiDE8Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as indexYcrvmJM2u7Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as indexSNXce0pZtOMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as index3pciauMqwAMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as index7k6LyVeZrFMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexiQEHzMtekaMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as indexFE6jpVUYPwMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logsX5EoLyaz77Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as add7o9U7xpZ3jMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45members5pdKOJgugcMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as index4U45PQTjICMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as indexE6wpJVwTJ1Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_93czRFgo2RylMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as indexulLwK7GHuqMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_93xb8MJtoR5CMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emailTEltm2t8rnMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45lists40u1GMLBM1Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexysLTqPGeBTMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93CpA9M9fXbgMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as membersH2642z8XozMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statisticsrHMYVslYvuMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_93yJuYD7D501Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as indexOCxz93203pMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addd3sFlIrvO5Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93WqRKo9x1TAMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as indexgYMsZCkqnbMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_93MsTy76nYUYMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45codeeRzZ3FiYN1Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screeningsQ6GF0eL9ruMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as indexzMz3S9j90nMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as invite7FUZettAsHMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_93NNXYq0lKtvMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as tickets0FoscBmO2SMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as index7dBUsJMyqoMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboardWApgb1dwOVMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as indext5jmlYRRxEMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93mswAzf1upVMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as addleUGEvS7rDMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addrgQqylQrIBMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as indexVYPhvJ1ux5Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companiesFBb5A8mnJWMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as index4Q04mt6S35Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordTc16PrUPfOMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as indexPjP7FvWufpMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoicesUNonkcZVSoMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as applyM982UpisHYMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93hvAC19K7wuMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_936lY2CDCdmtMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as indexkHXxeJCdoZMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as index7ehwV9yyk7Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93TFsuHlZXokMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as membersi0LDR2v2CwMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as index5VVSUdS4sAMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_93m3JYeIh4NnMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_93xBGoV5qlL8Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexjUuUgNjGctMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_93oEAC4HVaYnMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_93RXhn5leoLaMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetzDXCYyIrT2Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as index8UuRXxHsrRMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_93NaiuG9iDocMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_93nQrbijtGFYMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_936aT4lczSZ3Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callbackQFCm79ik0bMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logoutg95NDO0PC5Meta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as indexkkeJy3MsdkMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_93tExNfpjd2CMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93DEkwUDt4HbMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_93aluSUukqhOMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexlvgz8pVCjGMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93QbcnCpN65mMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_93p0PoB6X4ayMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as ticketsYRLzog75ExMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as vote0EUGS5SaVBMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubYk2n8En5mjMeta } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubYk2n8En5mj } from "/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookupVj0ifXYucAMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companiesF8kniFV9mLMeta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: addVPQHIVCO8oMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deleted2Dy9Y083NFMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_93I8k3BERfXNMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: indexSgja2ryWZ0Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_93G30dhEX0z0Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_93IQ0fRdwW3HMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: indexfRNUQE2YBAMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: index28HwQIgmKVMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardZrfqY0rQW0Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addKfTGuPFV0mMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_933NYfmS9OpmMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_93b665KpCE3kMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: index6OMOdslrlDMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_938GDkheiDE8Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: indexYcrvmJM2u7Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logsX5EoLyaz77Meta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: indexSNXce0pZtOMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: index3pciauMqwAMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: index7k6LyVeZrFMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexiQEHzMtekaMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: indexFE6jpVUYPwMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: membersH2642z8XozMeta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: add7o9U7xpZ3jMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45members5pdKOJgugcMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: index4U45PQTjICMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: indexE6wpJVwTJ1Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_93czRFgo2RylMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: indexulLwK7GHuqMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_93xb8MJtoR5CMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45lists40u1GMLBM1Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexysLTqPGeBTMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93CpA9M9fXbgMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statisticsrHMYVslYvuMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_93yJuYD7D501Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: indexOCxz93203pMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45codeeRzZ3FiYN1Meta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addd3sFlIrvO5Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93WqRKo9x1TAMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: indexgYMsZCkqnbMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_93MsTy76nYUYMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: tickets0FoscBmO2SMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: indexzMz3S9j90nMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: invite7FUZettAsHMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93NNXYq0lKtvMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: index7dBUsJMyqoMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboardWApgb1dwOVMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: indext5jmlYRRxEMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93mswAzf1upVMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: addleUGEvS7rDMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addrgQqylQrIBMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: indexVYPhvJ1ux5Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: index4Q04mt6S35Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordTc16PrUPfOMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexPjP7FvWufpMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93hvAC19K7wuMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_936lY2CDCdmtMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: index7ehwV9yyk7Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93TFsuHlZXokMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: index5VVSUdS4sAMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_93m3JYeIh4NnMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexjUuUgNjGctMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_93oEAC4HVaYnMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_93RXhn5leoLaMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetzDXCYyIrT2Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: index8UuRXxHsrRMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_93NaiuG9iDocMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_93nQrbijtGFYMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_936aT4lczSZ3Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callbackQFCm79ik0bMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logoutg95NDO0PC5Meta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: ticketsYRLzog75ExMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93QbcnCpN65mMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: vote0EUGS5SaVBMeta || {},
    component: () => import("/tmp/codebuild/output/src1021/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubYk2n8En5mjMeta?.name,
    path: "/judge-application",
    component: component_45stubYk2n8En5mj
  },
  {
    name: component_45stubYk2n8En5mjMeta?.name,
    path: "/profile",
    component: component_45stubYk2n8En5mj
  },
  {
    name: component_45stubYk2n8En5mjMeta?.name,
    path: "/login",
    component: component_45stubYk2n8En5mj
  },
  {
    name: component_45stubYk2n8En5mjMeta?.name,
    path: "/join",
    component: component_45stubYk2n8En5mj
  },
  {
    name: component_45stubYk2n8En5mjMeta?.name,
    path: "/memberships/my_membership",
    component: component_45stubYk2n8En5mj
  }
]